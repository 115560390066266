<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  created() {
    this.loadProduct();
  },
  methods: {
    loadProduct() {

    }
  }
}
</script>
